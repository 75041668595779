<template>
    <div>
      <div class="flex md12">
        <va-card :title="'find users'">
          <div class="row align--center">
            <div class="flex xs12 md3">
              <va-input
                v-model="term"
                :placeholder="'Search by name or email'"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3">
              <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>
            <va-data-table
              v-if="notSearching"
              :fields="fields"
              :data="users"
              api-mode
              :loading="loading"
              :per-page="parseInt(perPage)"
              :totalPages="totalPages"
              @page-selected="handlePageChange"
            >
            <!-- Avatar IMG -->
            <template
            slot="avatar"
            slot-scope="props"
            >
              <va-avatar>
                <img
                  :src="props.rowData.photoURL"
                  class="avatar data-table-server-pagination---avatar"
                >
              </va-avatar>
            </template>
            <!-- /end -  Avatar IMG -->
            <!-- /start - lastLogin -->
            <template
              slot="lastLogin"
              slot-scope="props"
            >
              {{ props.rowData.lastLogin ? $moment(props.rowData.lastLogin.seconds * 1000).format('MM/DD/YYYY') : 'N/A' }}
            </template>
            <!-- /end - lastLogin -->
          </va-data-table>
          <va-card v-else>
            <div class="text-center">
             <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            </div>
          </va-card>
        </va-card>
      </div>
    </div>
  </template>
  
  <script>
  import firebase from 'firebase';
  export default {
    name: 'ServerSide',
    /**
     * @description Setup the data for the component
    */
    data() {
      return {
        loading: true,
        users: [],
        term: "",
        notSearching: true,
        pageSnapshots: [],
        cachedPages: {},
        //current page is the current page number
        currentPage: 1,
        //total pages is the pagination number [1,2,3,4,5...]
        totalPages: 5,
        //per page is the amount of items per page
        perPage: '10',
        perPageOptions: ['5','10', '50', '100', '250'],
      };
    },
    async created() {
      await this.getPagination();
      await this.fetchUsers(this.currentPage, this.perPage);
    },
    /**
     * @description Watch for changes in the pagination and update the table
   */
    watch: {
      currentPage: {
        handler: function () {
          if(!this.notSearching) return;
          this.fetchUsers(this.currentPage, this.perPage);
        },
      },
      term: {
        handler: function () {
          this.debouncedSearch();
        },
      },
      perPage: {
        handler: async function () {
          this.resetCache();
          await this.getPagination();
          if (this.term) {
            this.searchUsers(this.term, 0, this.perPage);
          } else {
            await this.fetchUsers(this.currentPage, this.perPage);
          }
        },
      },
    },
    /**
     * @description Handle/Fetch the page change event
    */
    methods: {
      debouncedSearch: _.debounce(async function () {
        if (this.term) {
          this.notSearching = false;
          await this.searchUsers(this.term, 0, this.perPage);
          this.notSearching = true;
        } else {
          this.notSearching = false;
          this.resetCache();
          await this.getPagination();
          await this.fetchUsers(1, this.perPage);
          this.notSearching = true;
        }
      }, 500),
      resetCache() {
        this.cachedPages = {};
        this.pageSnapshots = [];
      },
      handlePageChange(page) {
        if (this.term) {
          this.searchUsers(this.term, (page - 1), this.perPage);
        }else{
          this.currentPage = page;
        }
      },
      async searchUsers(searchTerm, page, itemsPerPage) {
        this.loading = true;
        try {
          const result = await axios.post(window.firebaseURL + `api/admin/searchUsers`, {
            search: searchTerm,
            hitsPerPage: parseInt(itemsPerPage),
            page: page,
          })
          this.users = result.data?.data.hits;
          this.totalPages = result.data?.data?.totalPages;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }
      },
      async getPagination() {
          let usersTable = firebase.firestore().collection('users');
          let querySnapshot = await usersTable.get();
          const totalItems = querySnapshot.size;
          const itemsPerPage = parseInt(this.perPage);
          this.totalPages = Math.floor(totalItems / itemsPerPage);
       },
       /**
       * Fetches a page of users and stores the results in the component's data.
       * Utilizes caching to minimize the number of queries to the Firestore.
       *
       * @param {number} page - The target page number to fetch.
       * @param {number} itemsPerPage - The number of items to display per page.
      */
      async fetchUsers(page, itemsPerPage) {
        console.log('warning, fetching users')
        this.loading = true;
        this.users = [];
        const pageSize = parseInt(itemsPerPage);
        const targetPage = page;
        
        // Check if the requested page is already in the cache
        if (this.cachedPages[targetPage] && !search) {
          this.users = this.cachedPages[targetPage];
          this.loading = false;
          return;
        }
        
        let usersTable = firebase.firestore().collection('users');
        let usersQuery = usersTable.orderBy('email').limit(pageSize);
        
        // Use the cached boundaries, if available
        if (targetPage > 1) {
          if (this.pageSnapshots[targetPage - 1]) {
            // Reuse the stored snapshot for the previous page
            usersQuery = usersQuery.startAfter(this.pageSnapshots[targetPage - 1].end);
          } else {
            const skips = (targetPage - 1) * pageSize;
            const lastVisible = await usersTable.orderBy('email').limit(skips).get().then((snapshot) => {
              return snapshot.docs[snapshot.docs.length - 1];
            });
            usersQuery = usersQuery.startAfter(lastVisible);
          }
        }
 
        let querySnapshot = await usersQuery.get();
        const fetchedUsers = [];
     
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          fetchedUsers.push({
            photoURL: data.photoURL,
            displayName: data.displayName,
            provider: data.provider,
            email: data.email,
            lastLogin: data.lastLogin,
          });
        });
        // Store the fetched users for the requested page in the cache
        this.cachedPages[targetPage] = fetchedUsers;
        this.users = fetchedUsers;
        // Store the start and end boundaries of the page in the cache
        this.pageSnapshots[targetPage] = {
          start: querySnapshot.docs[0],
          end: querySnapshot.docs[querySnapshot.docs.length - 1],
        };
        this.loading = false;
      },
    },  
    /**
     * Setup Table/Filters
    */
    computed: {
      fields() {
        return [{
          name: '__slot:avatar',
          width: '30px',
          height: '45px',
          dataClass: 'text-center',
        }, {
          name: 'displayName',
          title: this.$t('tables.headings.name'),
          width: '30%',
        }, {
          name: 'provider',
          title: 'Provider',
          width: '20%',
        }, {
          name: 'email',
          title: this.$t('tables.headings.email'),
          width: '30%',
        },
        {
          name: '__slot:lastLogin',
          title: 'Last Login',
        }];
      },
    },
  };
  </script>
